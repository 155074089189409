import React from "react";
import styles from "./Pricing.module.sass";
import TextInput from "../../../../components/TextInput";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Card from "../../../../components/Card";
import Dropdown from "../../../../components/Dropdown_c";
import { useEffect } from "react";
import Checkbox from "../../../../components/Checkbox";

const Pricing = ({
  className,
  recurringType,
  productCurrency,
  selectCurrency,
  setSelectCurrency,
  userChoose,
  oneTime,
  setOneTime,
  subscriptions,
  setSubscriptions,
  selectPeriod,
  setSelectPeriod,
  productPrice,
  setProductPrice,
  setValueHandler,
  priceError,
  archived,
  cost,
  margin,
  setCost,
  setMargin,
  showOneTime,
  showSubscription
}) => {

  useEffect(() => {
    const productPriceNum = parseInt(productPrice || "0");
    const costPriceNum = parseInt(cost || "0");

    if (productPriceNum && costPriceNum) {
      setMargin((((productPriceNum - costPriceNum) / productPriceNum) * 100))
    } else {
      setMargin(null);
    }

    if(showOneTime){
      setSubscriptions(false);
      setOneTime(true);
    }

    if(showSubscription){
      setOneTime(false);
      setSubscriptions(true);
    }

  }, [productPrice, cost])

  const handleSubscriptions = () => {
    setSubscriptions(!subscriptions);
    setOneTime(!oneTime);
  };

  const handleOneTime = () => {
    setSubscriptions(!subscriptions);
    setOneTime(!oneTime);
  };

  return (
    <>
      <Card
        className={cn(styles.card, className)}
        title='Pricing'
      >
        {showOneTime ? (
          ""
        ) : (
          <>
            <div className={styles.row}>
              <div
                  className={styles.col}
                  style={showSubscription ? { pointerEvents: "none" } : null}
              >
                <div className={styles.field}>
                  <div className={styles.fieldLabel}>
                    <span>Billing Period</span>
                  </div>
                </div>
                <div className={styles.includeTax}>
                  <Checkbox
                    onChange={handleOneTime}
                    value={oneTime}
                  />
                  <span className={styles.txt}>Regular</span>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div
                className={styles.col}
                style={showSubscription ? { pointerEvents: "none" } : null}
              >
                <div className={styles.chkSub}>
                  <Checkbox
                    onChange={handleSubscriptions}
                    value={subscriptions}
                  />
                  <span className={styles.txt}>Subscription</span>
                </div>
              </div>
              <div
                className={styles.col}
              >
                <div className={styles.chkSub}>
                  {subscriptions ? (
                      <Dropdown
                        className={styles.userChooseDrop}
                        classDropdownHead={styles.dropdownHead}
                        value={selectPeriod}
                        setValue={setSelectPeriod}
                        options={recurringType}
                      />
                    ) : (
                      ""
                    )}
                </div>
              </div>
            </div>
          </>
        )}

        {!userChoose ? (
          <div
            className={styles.row}
            style={archived ? { pointerEvents: "none" } : null}
          >
            <div className={styles.col}>
              <div>
                <div className={styles.priceGroup}>
                  <TextInput
                    style={
                      productPrice === "" || productPrice === null
                        ? {
                          backgroundColor: "#FFBC9925",
                          borderColor: "#FFBC9925",
                          width: 150,
                        }
                        : { width: 150 }
                    }
                    label='Price'
                    //currency={currency}
                    name='price'
                    value={productPrice}
                    onChange={(event) =>
                      setValueHandler(event?.target?.value, setProductPrice)
                    }
                    required
                  />
                  <Dropdown
                    className={styles.dropdown}
                    classDropdownHead={styles.dropdownHead}
                    value={selectCurrency}
                    setValue={setSelectCurrency}
                    options={productCurrency}
                  />
                </div>
                {priceError !== "" ? (
                  <div className={styles.errorContainer}>
                    <span className={styles.errorMessage}>{priceError}</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.colCost}>
              <div className={styles.costMarginWrapper}>
                <TextInput
                  style={{ width: 158 }}
                  label='Cost'
                  name='cost'
                  type="number"
                  value={cost}
                  onChange={(event) => {
                    setValueHandler(event?.target?.value, setCost);
                  }}
                  required
                  percentage={true}
                />
                <TextInput
                  style={{ width: 158, pointerEvents: 'none' }}
                  label='Margin'
                  name='margin'
                  text='%'
                  value={(margin || 0)?.toFixed(2)}
                  required
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </Card>
      <TooltipGlodal />
    </>
  );
};

export default Pricing;
