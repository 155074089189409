import React, { useEffect, useState } from 'react';
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Modal from '../Modal';
import TextInput from '../TextInput';
import Card from "../../components/Card"
import styles from './Email.module.sass'
import { getSendInvoiceEmailDetail, getSendSubscriptionEmailDetail, sendInvoiceWithEmailPopup, sendSubscriptionWithEmailPopup } from '../../utils/apiCallHanlder';
import Spinner from '../../utils/spinner';
import { toast } from 'react-toastify';
import { toastConfiguration } from '../../utils/utils';

const toolbarActions = [
    "bold",
    "italic",
    "strike",
    "link",
    "heading1",
    "quote",
    "bullet",
    "number",
    "centered",
    "undo",
    "redo",
];

const EmailPopup = ({ showModal, closeModalHandler, isInvoice, id, postSendHandler, showSendInvoiceButton, setShowEmailModal }) => {
    const [loading, setLoading] = useState(true);
    const [sendLoading, setSendLoading] = useState(false);

    const [to, setTo] = useState('');
    const [cc, setCC] = useState('');
    const [from, setFrom] = useState('');
    const [subject, setSubject] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [details, setDetails] = useState({});

    useEffect(() => {
        if (id && id !== 'add' && showModal) {
            if (isInvoice) {
                getInvoiceEmailDetails();
            } else {
                getSubscriptionEmailDetails();
            }
        }
    }, [id, showModal])

    const getInvoiceEmailDetails = async () => {
        const { data } = await getSendInvoiceEmailDetail(id);
        if (data) processResponseData(data);
        setLoading(false);
    }

    const getSubscriptionEmailDetails = async () => {
        const { data } = await getSendSubscriptionEmailDetail({ id });
        if (data) processResponseData(data);
        setLoading(false);
    }


    const processResponseData = (data) => {
        const { sendDetail } = data;
        setTo(sendDetail?.to);
        setCC(sendDetail?.cc)
        setFrom(sendDetail?.from);
        setSubject(sendDetail?.subject);
        setTitle(sendDetail?.title);
        setBody(sendDetail?.emailBody);
        setDetails(sendDetail);
    }

    const handleTrixInputChange = (e, newValue) => {
        setBody(newValue);
    };

    const sendHandler = async () => {
        if (sendLoading) {
            return;
        }
        if (!to || !from || !subject || !body) {
            toast.error('Please fill out all information before proceeding', toastConfiguration);
            return;;
        }

        setSendLoading(true);

        const payload = {
            ...(details || {}),
            to,
            cc,
            from,
            title,
            subject,
            emailBody: body,
            invoiceId: showSendInvoiceButton ? details?.invoiceId : id,
            isSendInvoice: showSendInvoiceButton ? true : undefined,
        }

        const { data, error } = isInvoice ? await sendInvoiceWithEmailPopup(payload) :
            await sendSubscriptionWithEmailPopup(payload);

        if (data) {
            closeModalHandler();
            postSendHandler(data?.invoice, to)
        } else {
            toast.error(error || `Error in sending ${isInvoice ? "invoice" : "subscription"}`, toastConfiguration);
        }
    }

    return (
        loading ?
            <div className={styles.spinner}>
                <Spinner size={48} color={'gray'} />
            </div>
            :
            
        <Card
        title={title}
        head={
            <button onClick={() => setShowEmailModal(false)} className='button-stroke-red'>Cancel</button>
        }
        border
    >
            <div className={styles.fieldsContainer}>
                
                <div className={styles.inputWrap}>
                <TextInput
                    className={styles.field}
                    classInput={styles.input}
                    label="From"
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                    disabled
                />
                <TextInput
                    className={styles.field}
                    classInput={styles.input}
                    label="To:"
                    value={to}
                    disabled
                />
                <TextInput
                    className={styles.field}
                    label="cc:"
                    value={cc}
                    onChange={(e) => setCC(e.target.value)}
                //disabled
                />
                </div>
                <TextInput
                    label="Subject:"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                //disabled
                />

                <div className={styles.detailsWrap}>
                    <ReactTrixRTEToolbar
                        toolbarActions={toolbarActions}
                        toolbarId='react-trix-rte-editor'
                    />
                    <ReactTrixRTEInput
                        toolbarId='react-trix-rte-editor'
                        onChange={handleTrixInputChange}
                        defaultValue={body}
                    />
                </div>


                {/* <TextInput
                    label=""
                    value={from}
                    disabled
                /> */}

                <div className={styles.buttonContainer}>
                    {isInvoice && (<button className='button-stroke-red' onClick={closeModalHandler}>Continue editing</button>)}
                    <button className='button' onClick={sendHandler} style={{ width: isInvoice ? 146 : 176 }}>
                        {sendLoading ? <Spinner size='24' color={'white'} /> :
                            `Send ${isInvoice ? "Invoice" : "Subscription"}`}
                    </button>
                </div>
            </div>
        </Card>
        
    )
}

export default EmailPopup;